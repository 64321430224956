import React from 'react';
import { Link } from 'gatsby';
import ImgLogo from '../../static/img/logo.svg';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
} from 'reactstrap';
import PropTypes from 'prop-types';

class NavBar extends React.Component {
  constructor(args) {
    super(args);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <div>
        <Navbar color="dark" dark expand="md">
          <Container>
            <NavbarBrand href="/" className="mb-0 h1">
              <img
                height="30px"
                src={ImgLogo}
                alt="beyer-tom.de logo"
                className="d-inline-block align-top"
              />
              &nbsp;Tom Beyer
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {this.props.topNavigation.map((link) => (
                  <NavItem key={link.id}>
                    <Link to={link.url} className="nav-link">
                      {link.title}
                    </Link>
                  </NavItem>
                ))}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default NavBar;

NavBar.propTypes = {
  topNavigation: PropTypes.oneOfType([PropTypes.array]),
};

NavBar.defaultProps = {
  topNavigation: false,
};
