import React, { Fragment } from 'react';
import { css } from '@emotion/core';
import { StaticQuery, graphql } from 'gatsby';
import NavBar from './NavBar';
import SEO from './SEO';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import './layout.css';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            topNavigation {
              id
              title
              url
            }
          }
        }
      }
    `}
    render={(data) => (
      <Fragment>
        <SEO />
        <NavBar
          topNavigation={data.site.siteMetadata.topNavigation}
          siteTitle={data.site.siteMetadata.title}
        />
        <Container>
          <main
            id="main"
            role="main"
            className="container"
            css={css`
              padding-top: 1rem;
              background-color: #fff;
              padding-bottom: 50px;
            `}
          >
            {children}
          </main>
        </Container>
      </Fragment>
    )}
  />
);

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
};

Layout.defaultProps = {
  children: false,
};
